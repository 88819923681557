.icon-angle-left-sq {
	width: 647.04px;
	height: 970.32px;
}

.icon-angle-right-sq {
	width: 647.04px;
	height: 970.32px;
}

.icon-cross {
	width: 12px;
	height: 12px;
}

.icon-menu {
	width: 1000px;
	height: 750px;
}

.icon-square {
	width: 16px;
	height: 16px;
}

.icon-support {
	width: 40px;
	height: 47px;
}

